const togglers = document.querySelectorAll('[data-target="modal"]');
const cssClass = {
    show: 'show',
};

/**
 * Closes modal
 */
const closeModal = () => {
    const backdrop = document.querySelector('.modal-backdrop.show');

    // return if backdrop not exists
    if (!backdrop) return;

    backdrop.classList.remove(cssClass.show);
    backdrop.querySelector('.modal-wrapper.show').classList.remove(cssClass.show);
    setTimeout(() => {
        backdrop.remove();
        document.body.removeAttribute('style');
    }, 250);
};

/**
 * Shows modal
 * @param {Element} backdrop
 * @param {Element} wrapper
 */
const fadeIn = (backdrop, wrapper) => {
    requestAnimationFrame(() =>
        setTimeout(() => {
            document.body.style.cssText = `
                overflow: hidden;
                padding-right: ${window.innerWidth - document.documentElement.clientWidth}px;
            `;
            backdrop.classList.add(cssClass.show);
        })
    );
    requestAnimationFrame(() =>
        setTimeout(() => {
            wrapper.classList.add(cssClass.show);
        })
    );
};

/**
 * Appends wrapper of modal to the backdrop
 * @param {Element} backdrop
 * @returns {Element} wrapper
 */
const appendContentWrapper = (backdrop) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('modal-wrapper');
    backdrop.append(wrapper);
    return wrapper;
};

/**
 * Appends modal backdrop to body
 * @returns {Element} backdrop
 */
const addBackdrop = () => {
    const backdrop = document.createElement('div');
    backdrop.classList.add('modal-backdrop');
    document.body.append(backdrop);
    return backdrop;
};

/**
 * Shows modal content
 * @param {String} eventId
 * @param {String} source
 * @param {Function} callback
 */
const openModal = (videoId) => {
    const backdrop = addBackdrop();
    const wrapper = appendContentWrapper(backdrop);
    fadeIn(backdrop, wrapper);
    wrapper.innerHTML = `
        <div class="modal-content ratio ratio-16x9">
            <iframe width="100%" height="644" src="https://www.youtube.com/embed/${videoId}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    `;
};

/**
 * Handles click on modal toggler
 * @param {Event} e
 */
const handleClickOnToggler = (toggler) => {
    const id = toggler.getAttribute('data-video');
    if (id) openModal(id);
};

/**
 * Handles click on backdrop
 * @param {Event} e
 * @returns
 */
const handleClickOnBackdrop = (e) => {
    if (!e.target.matches('.modal-backdrop.show') && !e.target.matches('.modal-wrapper.show')) return;
    closeModal();
};

/**
 * Handles click on close button
 * @param {Event} e
 * @returns
 */
const handleClickOnCloseButton = (e) => {
    if (e.target.offsetParent) {
        if (!e.target.matches('.modal-close') && !e.target.offsetParent.matches('.modal-close')) return;
        closeModal();
    }
};

togglers.forEach((toggler) => {
    toggler.addEventListener('click', (e) => {
        e.preventDefault();
        handleClickOnToggler(toggler);
    });
});
document.addEventListener('click', (e) => {
    handleClickOnBackdrop(e);
    handleClickOnCloseButton(e);
});
window.addEventListener('keyup', (e) => {
    // esc key pressed?
    if (e.code === 'Escape') {
        // get shown modal
        const backdrop = document.querySelector('.modal-backdrop.show');
        if (backdrop) closeModal();
    }
});
